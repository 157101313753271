@import "../../../../scss/utils/variables";

.background {
  background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
  padding: 4rem 0;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 3rem 0;
  }
}

.dedicatedServersContainer {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 3rem;
  align-items: center;
  padding: 0 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
}

.dedicatedServersLeft {

  @media (max-width: 1025px) {
    margin: 0 auto;
  }
  .dedicatedServersHeader {
    font-size: 2.5rem;
    font-weight: 700;
    color: $blue-dark;
    margin-bottom: 1.5rem;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2rem;
      br {
        display: none;
      }
    }
  }

  .locationHeader {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid rgba($blue-header, 0.1);

    @media (max-width: 1025px) {
      justify-content: center;
    }

    
    


    .locationIcon {
      color: $blue-sub-header;
      font-size: 1.25rem;
    }

    .dedicatedServersSubHeader {
      font-size: 1.5rem;
      font-weight: 600;
      color: $blue-sub-header;
      margin: 0;


    }
  }

  .dedicatedServersText {
    max-width: 600px;

    p {
      font-size: 1rem;
      line-height: 1.6;
      color: #4a5568;
      margin-bottom: 1.25rem;

      @media (max-width: 768px) {
        font-size: 0.95rem;
      }
    }
  }

  .dedicatedServersButton {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.875rem 1.75rem;
    background: $blue-sub-header;
    color: white;
    font-size: 0.95rem;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 1rem;
    box-shadow: 0 4px 6px rgba($blue-sub-header, 0.2);

    &:hover {
      background: darken($blue-sub-header, 5%);
      box-shadow: 0 6px 12px rgba($blue-sub-header, 0.3);
    }

    .buttonIcon {
      font-size: 1rem;
      transition: transform 0.3s ease;
    }

    &:hover .buttonIcon {
      transform: translateX(4px);
    }
  }
}

.statesContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  img {
    max-width: 70%;
    height: auto;
    cursor: pointer;
    transition: all 0.3s ease;

    &.activeImage {
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 1024px) {
    img {
      max-width: 50%;
    }
  }

  @media (max-width: 768px) {
    img {
      max-width: 45%;
    }
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .dedicatedServersContainer {
    .dedicatedServersLeft {
      order: 2;
      text-align: center;
    }
    .statesContainer {
      order: 1;
    }
  }
}

@media (max-width: 768px) {
  .dedicatedServersContainer {
    padding: 0 0.5rem;
  }
}
