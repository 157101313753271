@import '../scss/utils/variables';

// Layout.module.scss

.layoutContainer {
    position: relative;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // Adjust opacity as desired
    z-index: 5; // Ensure it’s above content but below the header
  }
  