@import '../../scss/utils/variables';

.container {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.titleSection {
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  .divider {
    width: 60px;
    height: 4px;
    background-color: #007bff;
    margin: 1rem auto;
  }

  h3 {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;

    strong {
      color: #333;
    }
  }
}

.contentWrapper {
  margin-top: 2rem;
}

.cardsSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
}

.iconWrapper {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
}

.icon {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.cardContent {
  text-align: center;

  h4 {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    line-height: 1.6;
    font-size: 1rem;

    strong {
      color: #333;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 3rem 1rem;
  }

  .titleSection {
    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .cardsSection {
    grid-template-columns: 1fr;
  }
}
