@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&display=swap');

// Colors
$white: white;
$black: black;
$blue-dark: #09293B;
$blue-header: #0D3C56;
$blue-sub-header: #07587F;
$blue-tint: #FEFEFF;


// Fonts
$font-family-header: 'Montserrat', sans-serif;
$font-family-body: 'Montserrat', sans-serif;
$font-family-oswold: 'Oswald', sans-serif;

// Font Weight
$font-weight-bold: 800;

// Font Size
$font-size-nav: 16px;

// Font Colors
$text-light: #DADADA;

// Line Height
$text-line-height: 1.5;


// --------------------------------------- TYPOGRAPHY 
// --------------------- DESKTOP
// Header
$header-font-family: $font-family-header;
$header-font-size: 32px;
$header-font-weight: 600;
$header-color: $blue-header;
$header-bottom-gap: .5rem;


// Subheader
$subheader-font-size: 24px;
$subheader-font-weight: 400;
$subheader-color: $blue-sub-header;
$subheader-bottom-gap: 1rem;


// Text
$text-font-size: 16px;
$text-color: black;
$text-bottom-gap: 2rem;
$text-line-height: 1.5;

// --------------------- TABLET
// Header
$header-font-size-tablet: 28px;
$header-font-weight-tablet: 600;
$header-bottom-gap-tablet: 0.4rem;

// Subheader
$subheader-font-size-tablet: 20px;
$subheader-font-weight-tablet: 400;
$subheader-bottom-gap-tablet: 0.8rem;

// Text
$text-font-size-tablet: 15px;
$text-bottom-gap-tablet: 1.5rem;
$text-line-height-tablet: 1.4;


// --------------------- MOBILE
// Header
$header-font-size-mobile: 24px;
$header-font-weight-mobile: 600;
$header-bottom-gap-mobile: 0.3rem;

// Subheader
$subheader-font-size-mobile: 18px;
$subheader-font-weight-mobile: 400;
$subheader-bottom-gap-mobile: 0.6rem;

// Text
$text-font-size-mobile: 14px;
$text-bottom-gap-mobile: 1rem;
$text-line-height-mobile: 1.3;
 