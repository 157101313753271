@import '../../../scss/utils/variables';

.container {
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0A1F3F;
    // padding: 1rem;
    // margin-top: 15rem;

    @media (max-width: 1024px) {
        margin-top: 10rem;

      }
  
      @media (max-width: 600px) {
        margin-top: 5rem;

      }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
  }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.671); 
        z-index: 2;
    }

    .pattern {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        transform-origin: center;
        animation: rotatePattern 60s linear infinite;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        position: relative;
        z-index: 3;
        text-align: center;
        padding: 2rem;
        max-width: 900px;
        
        .heading {
            font-family: $font-family-header;
            font-weight: 700;
            font-size: 4.5rem;
            letter-spacing: 1px;
            margin-bottom: 1.5rem;
            background: linear-gradient(120deg, #ffffff, #0084ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: glowText 3s ease-in-out infinite;
            
            @media (max-width: 1024px) {
                font-size: 3.5rem;
            }
            
            @media (max-width: 600px) {
                font-size: 2.5rem;
            }
        }
        
        .subtext {
            font-family: $font-family-header;
            font-size: 1.5rem;
            font-weight: 300;
            margin-bottom: 3rem;
            opacity: 0.9;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            animation: float 4s ease-in-out infinite;
            
            @media (max-width: 1024px) {
                font-size: 1.25rem;
            }
            
            @media (max-width: 600px) {
                font-size: 1rem;
            }
        }
        
        .ctaButton {
            position: relative;
            display: inline-block;
            padding: 1.2rem 3rem;
            font-family: $font-family-header;
            font-size: 1.2rem;
            font-weight: 600;
            text-transform: uppercase;
            color: white;
            background: linear-gradient(135deg, #0084ff 0%, #0052cc 100%);
            border: none;
            border-radius: 4px;
            cursor: pointer;
            overflow: hidden;
            transition: all 0.3s ease;
            box-shadow: 0 4px 15px rgba(0, 132, 255, 0.3);
            
            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 8px 25px rgba(0, 132, 255, 0.4);
                
                .buttonGlow {
                    animation: slideGlow 1s ease-in-out;
                }
            }
            
            .buttonGlow {
                position: absolute;
                top: 0;
                left: -50%;
                width: 50%;
                height: 100%;
                background: linear-gradient(
                    90deg,
                    transparent,
                    rgba(255, 255, 255, 0.2),
                    transparent
                );
                transform: skewX(-25deg);
            }
            
            @media (max-width: 1024px) {
                font-size: 1rem;
                padding: 1rem 2.5rem;
            }
            
            @media (max-width: 600px) {
                font-size: 0.9rem;
                padding: 0.8rem 2rem;
            }
        }
    }
}

// Animation keyframes
@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes glowText {
    0%, 100% {
        filter: drop-shadow(0 0 5px rgba(0, 132, 255, 0.3));
    }
    50% {
        filter: drop-shadow(0 0 15px rgba(0, 132, 255, 0.5));
    }
}

@keyframes rotatePattern {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes slideGlow {
    from {
        transform: translateX(-100%) skewX(-25deg);
    }
    to {
        transform: translateX(200%) skewX(-25deg);
    }
}