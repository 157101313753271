@import '../../../../scss/utils/variables';

.col1 {
  position: relative;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    height: 700px;

    .header {
      font-family: $font-family-header;
      font-size: 32px;
      font-weight: 600;
      color: $blue-header;
      margin-bottom: 1rem;
      padding: 0rem 2rem;
      padding-top: 2rem;
    }

    .text {
      font-family: $font-family-header;
      font-size: 16px;
      color: #121214;
      margin-bottom: 1rem;
      width: 80%;
      padding: 0rem 2rem;
    }

    .errorText {
      color: red;
      padding: 0rem 2rem;
      margin-bottom: 1rem;
    }

    .successText {
      color: green;
      padding: 0rem 2rem;
      margin-bottom: 1rem;
    }

    .contactForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
        
      .formGroup {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0rem;
        border-top: 1px solid #e0e0e0;
        overflow: hidden;
      //   border-bottom: 1px solid #e0e0e0;

        input,
        textarea {
          width: 100%;
          border: none;
          padding: 0.5rem 2rem;
          width: 100%;
          margin: 0 auto;
          font-size: 16px;
          font-family: $font-family-header;
          background-color: #f5f5f5;
          color: #121214;
          outline: none;
          box-sizing: border-box;

          @media (max-width: 1024px) {
            font-size: 14px;
          }

          @media (max-width: 600px) {
            font-size: 12px;
          }

       

          &::placeholder {
            color: #b0b0b0;
            font-weight: 300;
          }
        }

        textarea {
          resize: none;
          font-size: 16px;

          @media (max-width: 1024px) {
            font-size: 14px;
          }

          @media (max-width: 600px) {
            font-size: 12px;
          }
        }
      }

      .formGroup2  {
          flex: 3;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 1rem 0rem;
          border-top: 1px solid #f5f5f5;
          overflow: hidden;
        //   border-bottom: 1px solid #e0e0e0;

          input,
          textarea {
            width: 100%;
            border: none;
            padding: 0.5rem 2rem;
            width: 100%;
            margin: 0 auto;
            font-size: 16px;
            font-family: $font-family-header;
            background-color: #f5f5f5;
            color: #121214;
            outline: none;
            box-sizing: border-box; // Ensure padding is included in total width/height

            @media (max-width: 1024px) {
              font-size: 14px;
            }
  
            @media (max-width: 600px) {
              font-size: 12px;
            }
         

            &::placeholder {
              color: #b0b0b0;
              font-weight: 300;

              @media (max-width: 1024px) {
                font-size: 14px;
              }
    
              @media (max-width: 600px) {
                font-size: 12px;
              }
            }
          }

          textarea {
            resize: none;
            font-size: 16px;
          }
        }

      .sendButton {
        display: inline-block;
        background-color: $blue-header;
        color: #fff;
        font-family: $font-family-header;
        text-align: center;
        text-transform: uppercase;


        font-size: 16px;
        width: 100%;
        padding: 1rem 0;
        margin-top: 1rem;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;

        @media (max-width: 1024px) {
          font-size: 14px;
        }

        @media (max-width: 600px) {
          font-size: 12px;
        }

        &:hover {
          background-color: darken($blue-header, 5%);
        }

        &:active {
          background-color: darken($blue-header, 10%);
        }
      }
    }
  }


  
// Tablet styles
@media (max-width: 1024px) {
.col1 .header {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
    margin-bottom: .5rem;
  }



.col1 .text {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
    margin-bottom: 1rem;

  }


}

// Mobile styles
@media (max-width: 600px) {
.col1 .header {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }

  
.col1 .text  {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
    margin-bottom: 1rem;

  }

}

/* Top-right corner animation */
.lottieTopRight {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}