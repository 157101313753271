@import 'utils/variables';

// Global Styles
html {
    scroll-behavior: smooth;
  }
  
body {
    margin: 0;
    color: $black; 
}

a {
    text-decoration: none;
    transition: color 0.3s ease;
}

h1,h2,h3,h4,h5,h6,p {
    margin: 0;
    padding: 0;
}

// Spacing
.containerSpacing {
    padding: 10rem 0;
}

.normalSpacing {
    margin-top: 2.5rem;
}


.header {
    font-family: $font-family-header;
    font-size: 32px;
    font-weight: 600;
    color: $blue-header;
    margin-bottom: .5rem;
    text-align: center;
    margin-bottom: .5rem;
}

.subHeader {
    font-family: $font-family-header;
    font-size: 24px;
    font-weight: 400;
    color: $blue-sub-header;
    margin-bottom: 1rem;
    text-align: center;
    margin-bottom: 1.5rem;

}

.text {
    font-family: $font-family-header;
    font-size: 16px;
    color: $blue-header;
    color:  #121214;
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
}

p {
    line-height: 1.5;
}