@import '../../../../scss/utils/variables';

.paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background: #f8f9fa;
    border-top: 1px solid #eaeaea;
    margin-top: 2rem;
    border-radius: 0 0 16px 16px;

    @media (max-width: 768px) {
        padding: 1rem;
        flex-direction: column;
        gap: 1rem;
    }
}

.recordsPerPage {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    label, span {
        color: $blue-dark;
        font-size: 14px;
    }

    select {
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        color: $blue-dark;
        background-color: white;
        cursor: pointer;
        min-width: 70px;

        &:focus {
            outline: none;
            border-color: #0084ff;
        }

        &:hover {
            border-color: #0084ff;
        }
    }
}

.pagination {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    button {
        min-width: 40px;
        height: 40px;
        padding: 0 0.75rem;
        border: 1px solid #ddd;
        background: white;
        color: $blue-dark;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover:not(:disabled) {
            background-color: #f0f7ff;
            border-color: #0084ff;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #f5f5f5;
        }

        &.active {
            background-color: #0084ff;
            border-color: #0084ff;
            color: white;
        }
    }
}

@media (max-width: 768px) {
    .paginationContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .recordsPerPage {
        width: 100%;
        justify-content: center;
    }

    .pagination {
        width: 100%;
        justify-content: center;
    }
}
  