@import '../../../../scss/utils/variables';

.marginTop {
    margin-top: 2rem;
}

span {
    font-weight: 600;
    color: $blue-dark;
}

.background {
    position: relative;
    background: linear-gradient(to bottom, #f7f9fc 0%, #ffffff 100%);
    font-family: $font-family-header;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
    }

    .container {
        margin-top: 6rem;
        display: flex;

        .col1 {
            flex: 3;
            background-color: rgba(247, 249, 252, 0.7);
            padding: 5rem;

            @media (max-width: 1024px) {
                padding: 5rem 3rem;
            }

            @media (max-width: 700px) {
                padding: 4rem 2rem;
            }

            .header {
                font-family: $font-family-header;
                font-size: 3rem;
                font-weight: 700;
                color: $blue-dark;
                margin-bottom: 2rem;
                position: relative;
                line-height: 1.2;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    left: 0;
                    width: 60px;
                    height: 4px;
                    background: $blue-sub-header;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: transform 0.3s ease;
                }

                &.animate::after {
                    transform: scaleX(1);
                }

                @media (max-width: 768px) {
                    font-size: 2.5rem;
                }
            }

            .section {
                margin-top: 3rem;
                h3 {
                    font-family: $font-family-header;
                    font-size: 1.75rem;
                    font-weight: 600;
                    color: $blue-sub-header;
                    margin-bottom: 1.5rem;
                    position: relative;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        width: 40px;
                        height: 2px;
                        background: $blue-sub-header;
                        opacity: 0.5;
                    }

                    @media (max-width: 768px) {
                        font-size: 1.5rem;
                    }
                }

                p {
                    font-family: $font-family-header;
                    font-size: 1.1rem;
                    line-height: 1.8;
                    color: #4a4a4a;
                    margin-bottom: 1.5rem;
                    max-width: 800px;

                    @media (max-width: 768px) {
                        font-size: 1rem;
                        line-height: 1.7;
                        width: 100%;
                    }

                    a {
                        color: $blue-sub-header;
                        text-decoration: none;
                        font-weight: 600;
                        transition: all 0.3s ease;

                        &:hover {
                            color: darken($blue-sub-header, 10%);
                        }
                    }
                }

                ul {
                    li {
                        font-family: $font-family-header;
                        font-size: 1.1rem;
                        line-height: 1.8;
                        color: #4a4a4a;
                        margin: 1rem 0;
                        max-width: 800px;

                        @media (max-width: 768px) {
                            font-size: 1rem;
                            line-height: 1.7;
                            width: 100%;
                        }
                    }
                }
            }
        }
        
        .col2 {
            flex: 1;
            background-color: $blue-dark;
            padding: 5rem 1rem;

            @media (max-width: 1024px) {
                padding: 5rem .75rem;
            }

            @media (max-width: 700px) {
                padding: 4rem .5rem;
            }

            @media (max-width: 640px) {
                display: none;            
            }

            .list {
                margin-top: 4.5rem;
                position: sticky;
                top: 5rem;
                list-style: none;

                .listItem {
                    font-family: $font-family-header;
                    font-size: 1rem;
                    color: rgba(255, 255, 255, 0.7);
                    margin: 1rem 0;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    padding: 0.5rem 1rem;
                    border-radius: 6px;

                    &:hover {
                        color: white;
                        background: rgba(255, 255, 255, 0.1);
                    }

                    &.active {
                        color: white;
                        background: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }
    }
}

// Tablet styles
@media (max-width: 1024px) {
    .background .container .col1 .header {
        font-size: 2.5rem;
    }

    .background .container .col1 .section h3 {
        font-size: 1.5rem;
    }

    .background .container .col1 .section p,
    .background .container .col1 .section ul li {
        font-size: 1rem;
    }
}

// Mobile styles
@media (max-width: 700px) {
    .background .container .col1 .header {
        font-size: 2rem;
    }

    .background .container .col1 .section h3 {
        font-size: 1.25rem;
    }

    .background .container .col1 .section p,
    .background .container .col1 .section ul li {
        font-size: 0.95rem;
    }
}