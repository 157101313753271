@import "../../../src/scss/utils/variables";

.background {
  background-color: #f7f9fc;

  .container {
    max-width: 1200px;
    width: 80%;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .errorPage {
      text-align: center;
      h1 {
        font-size: 200px;
        color: $blue-dark;
      }

      h2, p {
        font-family: $font-family-header;
        margin-bottom: 1rem;
      }

      a {
        display: inline-block;
        padding: .8rem 3rem;
        border: .16rem solid $blue-header;
        color: $blue-header;
        box-sizing: border-box;
        text-transform: uppercase;
        font-family: $font-family-header;
        font-weight: 400;
        text-align: center;
        transition: all .15s;
        cursor: pointer;
    }

    a:hover {
        color: lighten($blue-header, 20%);   // Lighten the color by 10%
        border-color: lighten($blue-header, 20%);
        cursor: pointer;

    }

    }
  }
}
