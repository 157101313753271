@import "../../../scss/utils/variables";

.backgroundImage {
  // background-image: url("../../../Assets/Hero3.png");
  // background-image: url("../../../Assets/Hero3dark.png");
  background-image: url("../../../Assets/Hero3darkUpscaled_optimized.webp");
  background-color: $blue-dark;
  // background-image: url("../../../Assets/alternateHero.png");
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative; // Keep it relative to control layering
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 1024px) {
    background-attachment: fixed;
  }

  @media (max-width: 700px) {
    background-image: url("../../../Assets/mobileHero_optimized.webp");
    background-position: center center;
    background-size: cover;
    align-items: start;
  }

  // Filter the Image
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: darken;
  filter: contrast(1.15);

  // Center Overlay Text
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    z-index: 1;
  }

  .overlayText {
    margin-top: 5rem;
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 2;
    text-align: center;
    position: relative;
    padding: 0 20px;
    max-width: 1200px;
    width: 100%;

    @media (max-width: 700px) {
      margin-top: 0rem;
      height: 80%;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      padding: 7rem 2rem;
    }

    h1 {
      font-family: $font-family-header;
      font-weight: 200;
      color: $text-light;
      font-size: 28px;
      margin-bottom: 10px;
      text-shadow: 10px 10px 10px rgb(0, 0, 0);
    }

    @media (max-width: 1024px) {
      h1 {
        font-size: 24px;
      }
    }

    @media (max-width: 600px) {
      h1 {
        font-size: 20px;
        text-align: left;
      }
    }

    h2 {
      font-family: $font-family-header;
      font-weight: $font-weight-bold;
      color: $white;
      font-size: 58px;
      margin-bottom: 30px;
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
    }

    @media (max-width: 1024px) {
      h2 {
        font-size: 48px;
      }
    }

    @media (max-width: 600px) {
      h2 {
        font-size: 32px;
        text-align: left;
      }
    }

    .buttonContainer {
      display: flex;
      gap: 1rem;
      justify-content: center;

      @media (max-width: 700px) {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
      }
    }

    .heroButton {
      display: inline-block;
      padding: 1rem 3.5rem;
      border: 0.16rem solid $white;
      color: $black;
      background-color: white;
      box-sizing: border-box;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      position: relative;
      z-index: 2;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

      &:hover {
        color: #313131;
        border-color: #dddddd;
      }

      &:active {
        color: #bbbbbb;
        border-color: #bbbbbb;
      }

      @media (max-width: 1024px) {
        font-size: 18px;
        padding: 0.9rem 2.5rem;
      }

      @media (max-width: 600px) {
        font-size: 16px;
        padding: 0.8rem 2rem;
      }
    }

    .heroButton2 {
      display: inline-block;
      padding: 1rem 3.5rem;
      border: 0.16rem solid $white;
      color: $white;
      box-sizing: border-box;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      position: relative;
      z-index: 2;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

      &:hover {
        color: #dddddd;
        border-color: #dddddd;
      }

      &:active {
        color: #bbbbbb;
        border-color: #bbbbbb;
      }

      @media (max-width: 1024px) {
        font-size: 18px;
        padding: 0.9rem 2.5rem;
      }

      @media (max-width: 600px) {
        font-size: 16px;
        padding: 0.8rem 2rem;
      }
    }
  }
}

.subtitle {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 1.4;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.scrollIndicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  position: relative;
}

.wheel {
  width: 4px;
  height: 8px;
  background-color: white;
  border-radius: 2px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  animation: scroll 1.5s ease-in-out infinite;
}

.scrollText {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@keyframes scroll {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}


