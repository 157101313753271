@import '../../../../scss/utils/variables';

.section {
  padding: 8rem 0;
  background: linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 1px 1px, rgba(0, 132, 255, 0.03) 1px, transparent 0);
    background-size: 40px 40px;
    pointer-events: none;
  }
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 0rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.textContent {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  position: relative;

  .title {
    font-family: $font-family-header;
    font-size: 2.5rem;
    font-weight: 700;
    color: $blue-dark;
    margin-bottom: 1rem;
    line-height: 1.2;
    position: relative;
    // display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background: $blue-sub-header;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.3s ease;
    }

    &.animate::after {
      transform: translateX(-50%) scaleX(1);
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .subtitle {
    font-family: $font-family-header;
    font-size: 1.5rem;
    font-weight: 600;
    color: $blue-sub-header;
    margin-bottom: 1.5rem;
    position: relative;
    // display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 2px;
      background: $blue-sub-header;
      opacity: 0.5;
    }

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .description {
    font-family: $font-family-header;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.featureCard {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 132, 255, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, $blue-sub-header, $blue-header);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 32px rgba(0, 132, 255, 0.1);
    border-color: rgba(0, 132, 255, 0.2);

    &::before {
      transform: scaleX(1);
    }

    .icon {
      transform: scale(1.1) rotate(5deg);
    }
  }

  .icon {
    width: 56px;
    height: 56px;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
    filter: drop-shadow(0 4px 8px rgba(0, 132, 255, 0.1));
  }

  .number {
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, $blue-sub-header, $blue-header);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 132, 255, 0.2);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .featureTitle {
    font-family: $font-family-header;
    font-size: 1.25rem;
    font-weight: 600;
    color: $blue-dark;
    margin-bottom: 1rem;
    position: relative;
    // display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 2px;
      background: $blue-sub-header;
      opacity: 0.5;
    }
  }

  .featureDescription {
    font-family: $font-family-header;
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    max-width: 250px;
    margin: 0 auto;
  }
}

// Animation classes
.fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fadeIn.visible {
  opacity: 1;
  transform: translateY(0);
}

.slideIn {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.slideIn.visible {
  opacity: 1;
  transform: translateX(0);
}

// Stagger animation for cards
@for $i from 1 through 5 {
  .featureCard:nth-child(#{$i}) {
    transition-delay: #{$i * 0.1}s;
  }
}

.background {
    background-color: #f7f9fc;  
    padding: 5rem 0;         
    position: relative;

    .container {
        width: 80%;
        margin: 0 auto;
        max-width: 1400px;
        border-radius: 10px;
        display: flex;   
        align-items: center; 
        position: relative;
        z-index: 100;
        // gap: 10rem;
        background-color: #FEFEFE;
        border: solid 1px #cccc;

        @media (max-width: 1240px) {
            flex-direction: column;
            align-items: flex-start;
        }




        .col1 {
            flex: 1;
            padding: 5rem 1rem;
            display: flex;
            flex-direction: column;
            z-index: 200;
            justify-content: center;

            
            .header {
                font-family: $font-family-header;
                font-size: $header-font-size;
                font-weight: $header-font-weight;
                color: $header-color;
                margin-bottom: $header-bottom-gap;
            }
            
            .subHeader {
                font-family: $font-family-header;
                font-size: $subheader-font-size;
                font-weight: $subheader-font-weight;
                color: $blue-sub-header;
                margin-bottom: $subheader-bottom-gap;
            }
             
            .text {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin-bottom: $text-bottom-gap;
                color: #121214;
                max-width: 500px;
            }

            .col1Content {
                height: 100%;
            }
        }

        .col2 {
            flex: 1;
            background-color: $blue-sub-header;
            padding: 5rem 1rem;
            display: flex;
            justify-content: center;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            margin-left: 5rem;

            @media (max-width: 1240px) {
                width: 100%;
                padding: 3rem 0;
                margin: 0;
                border-radius: 0 0 10px 10px;
            }

            .iconContainer {
                display: grid;              
                grid-template-columns: repeat(2, 1fr); 
                grid-template-rows: repeat(3, 1fr);  
                gap: 2rem;                 
                height: 100%;     
                
                @media (max-width: 1240px) {
                    grid-template-columns: repeat(3, 1fr); 
                    grid-template-rows: repeat(2, 1fr);  
                }

                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr); 
                    grid-template-rows: repeat(2, 1fr);  
                }

                .iconItem {
                    background-color: white;
                    width: 100%;             
                    height: 125px;   
                    width: 125px;       
                    display: flex;          
                    flex-direction: column;   
                    justify-content: center;   
                    align-items: center;      
                    text-align: center;
                    border-radius: 12px;      // Rounded corners for a softer look
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Deeper shadow for depth
                    transition: transform 0.2s, box-shadow 0.2s; // Smooth transitions on hover

                    img {
                        margin-bottom: 1rem; 
                        width: 35px;
                    }


                    &:hover {
                        transform: scale(1.1);
                        box-shadow: 0 6px 12px rgba(255, 255, 255, 0.2); // Enhanced shadow on hover
                    }

                    h6 {
                        font-size: 30px;
                        margin-bottom: 0.5rem; 
                        color: $blue-sub-header;
                    }

                    p {
                        font-family: $font-family-header;
                        font-size: 12px;
                        font-weight: 600;
                        color: #000000;
                    }
                }
            }
        }
    }
}

.blueStrip {
    background-color: $blue-header;
    width: 100%;
    height: 200px;
    margin-top: -200px;
    z-index: 0;
    position: absolute;
}


// Tablet styles
@media (max-width: 1024px) {
    .background .container .col1 .header {
      font-size: $header-font-size-tablet;
      font-weight: $header-font-weight-tablet;
      margin-bottom: $header-bottom-gap-tablet;
    }
  
    .background .container .col1 .subHeader {
      font-size: $subheader-font-size-tablet;
      font-weight: $subheader-font-weight-tablet;
      margin-bottom: $subheader-bottom-gap-tablet;
    }
  
    .background .container .col1 .text {
      font-size: $text-font-size-tablet;
      line-height: $text-line-height-tablet;
      margin-bottom: $text-bottom-gap-tablet;
    }
  
  
  }
  
  // Mobile styles
  @media (max-width: 600px) {
    .background .container .col1 .header {
      font-size: $header-font-size-mobile;
      font-weight: $header-font-weight-mobile;
      margin-bottom: $header-bottom-gap-mobile;
    }
    .background .container .col1 .subHeader {
      font-size: $subheader-font-size-mobile;
      font-weight: $subheader-font-weight-mobile;
      margin-bottom: $subheader-bottom-gap-mobile;
    }
  
    .background .container .col1 .text {
      font-size: $text-font-size-mobile;
      line-height: $text-line-height-mobile;
      margin-bottom: $text-bottom-gap-mobile;
    }
  
  }