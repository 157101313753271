@import '../../../../scss/utils/variables';

.bottomFooter {
    background-color: $blue-dark;
    color: white;
    display: flex;
    justify-content: center;
    padding: 1rem;

    p {
        font-family: $font-family-header;
        font-weight: 200;
        text-align: center;
        font-size: 12px;
    }
}