@import '../../../../scss/utils/variables';

.background {
  .container {
    width: 80%;
    margin: 5rem auto;
    max-width: 1400px;
    display: flex;
    padding: 5rem;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; // Enable wrapping for responsiveness

    @media (max-width: 700px) {
      padding: 0;
    }

    .col1 {
      flex: 1;
    }

    .col2 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        height: auto; // Maintain aspect ratio

        @media (max-width: 700px) {
          display: none;
        }
      }
    }
  }
}

.header {
  font-family: $font-family-header;
  font-size: $header-font-size;
  font-weight: $header-font-weight;
  color: $header-color;
  margin-bottom: $header-bottom-gap;
}

.subHeader {
  font-family: $font-family-header;
  font-size: $subheader-font-size;
  font-weight: $subheader-font-weight;
  color: $blue-sub-header;
  margin-bottom: $subheader-bottom-gap;
}

.text {
  font-family: $font-family-header;
  font-size: $text-font-size;
  margin-bottom: $text-bottom-gap;
  color: #121214;
  max-width: 500px;
}

.dedicatedServersButton {
  display: inline-block;
  padding: 0.6rem 1.5rem;
  border: 0.16rem solid $blue-sub-header;
  color: $blue-sub-header;
  text-transform: uppercase;
  font-family: $font-family-header;
  font-weight: 400;
  text-align: center;
  transition: all 0.15s;
}

// Responsive styling for screens up to 768px
@media (max-width: 1024px) {
  .container {
    flex-direction: column; // Stack columns vertically
    padding: 2rem;
    gap: 2rem;

    .col1, .col2 {
      width: 100%; // Take full width

    }

    .col1 {
      margin-bottom: 2rem; // Add spacing between text and image
    }

    .text {
      max-width: 100%; // Full width for better readability
    }

    .col2 img {
      max-width: 80%;
      height: auto;
    }
  }
}



// Tablet styles
@media (max-width: 1024px) {
  .background .container .col1 .header {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .background .container .col1 .subHeader {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .background .container .col1 .text {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }


}

// Mobile styles
@media (max-width: 600px) {
  .background .container .col1 .header {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }
  .background .container .col1 .subHeader {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .background .container .col1 .text {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }

}