@import "../../../scss/utils/variables";

.fullWidthBackground {
  position: fixed;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  z-index: 1100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  .container {
    background-color: transparent;
    width: 100%;
    max-width: 1400px;
    color: $black;
    padding: 1.2rem 2rem;

    .desktopNav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 200px;
        height: 52px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.02);
        }

        @media (max-width: 700px) {
          width: 140px;
          height: 38px;
        }
      }

      .mainNav {
        @media (max-width: 1000px) {
          display: none;
        }

        .mainNavList {
          display: flex;
          gap: 3.5rem;
          align-items: center;
          list-style-type: none;

          .mainNavListItem {
            position: relative;

            .dropdownIndicator {
              margin-left: 5px;
              width: 12px;
              height: 12px;
              vertical-align: middle;
              transition: transform 0.3s ease;
            }
            
            .downArrow {
              width: 18px;
              height: 18px;
              transition: transform 0.3s ease;
            }
            
            .rotated {
              transform: rotate(180deg);
            }

            .mainNavListItemLink {
              color: #2e2e2e;
              font-family: $font-family-header;
              text-transform: uppercase;
              font-size: 15px;
              font-weight: 600;
              text-decoration: none;
              padding: 2rem 0;
              transition: all 0.3s ease;
              letter-spacing: 0.5px;
              position: relative;

              &:hover,
              &.active {
                color: $blue-header;
              }

              // &::after {
              //   content: "";
              //   position: absolute;
              //   bottom: 1.5rem;
              //   left: 0;
              //   right: 0;
              //   height: 2px;
              //   background-color: $blue-header;
              //   transform: scaleX(0);
              //   transition: transform 0.3s ease;
              //   transform-origin: center;
              // }

              // &:hover::after,
              // &.active::after {
              //   transform: scaleX(1);
              // }
            }

            .dropdownMenu {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: calc(100% + 32px);
              background: rgba(255, 255, 255, 0.98);
              backdrop-filter: blur(10px);
              -webkit-backdrop-filter: blur(10px);
              box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
              padding: 0.5rem 0;
              z-index: 20;
              min-width: 220px;
              border-radius: 8px;
              border: 1px solid rgba(0, 0, 0, 0.05);
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease;
              list-style: none;

              &::before {
                content: "";
                position: absolute;
                top: -20px;
                left: 0;
                right: 0;
                height: 10px;
                background: transparent;
              }

              li {
                padding: 0.8rem 1.5rem;
                transition: all 0.2s ease;
                list-style: none;

                &:first-child {
                  padding-top: 1rem;
                }

                &:last-child {
                  padding-bottom: 1rem;
                }

                a {
                  color: #2e2e2e;
                  font-family: $font-family-header;
                  text-transform: uppercase;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 0.5rem 0;
                  transition: all 0.3s ease;
                  display: block;
                  position: relative;

                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: $blue-header;
                    transition: width 0.3s ease;
                  }

                  &:hover {
                    color: $blue-header;
                    padding-left: 5px;

                    &::after {
                      width: 100%;
                    }
                  }
                }
              }
            }

            &:hover .dropdownMenu {
              opacity: 1;
              visibility: visible;
              transform: translateX(-50%) translateY(0);
            }
          }
        }
      }

      .nav2 {
        @media (max-width: 1000px) {
          display: none;
        }
      
        .ul2 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          list-style-type: none;
      
          li {
            text-decoration: none;
      
            a {
              color: $blue-header;
              font-family: $font-family-header;
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 600;
              padding: 0.8rem 2rem;
              display: flex;
              align-items: center;
              border: 2px solid $blue-header;
              border-radius: 4px;
              position: relative;
              transition: all 0.3s ease;
              letter-spacing: 0.5px;
              background: transparent;
      
              &:hover {
                color: white;
                background-color: $blue-header;
                box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
                transform: translateY(-1px);
              }
            }
          }
        }
      }
      
      .hamburgerMenu {
        display: none;
        cursor: pointer;
        width: 30px;
        padding: 5px;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }

        @media (max-width: 1000px) {
          display: block;
        }
      }
    }

    .mobileNav {
      @media (min-width: 1000px) {
        display: none;
      }

      background: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
      position: absolute;
      width: 100%;
      top: 74px;
      left: 0;
      padding: 1rem 0;
      z-index: 1000;
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      .mobileNavList {
        list-style: none;
        padding: 0;
        text-align: left;

        li {
          padding: 0.8rem 1.5rem;
          transition: all 0.3s ease;

          &:hover {
            background-color: rgba(0, 123, 255, 0.05);
          }

          a {
            color: #2e2e2e;
            text-decoration: none;
            font-weight: 600;
            font-family: $font-family-header;
            text-transform: uppercase;
            font-size: 15px;
            letter-spacing: 0.5px;
            display: block;
            transition: all 0.3s ease;

            &:hover {
              color: $blue-header;
            }
          }
        }
      }

      .mobileNavItemWithArrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.8rem 1.5rem;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: rgba(0, 123, 255, 0.05);
        }

        .downArrow {
          width: 18px;
          height: 18px;
          transition: transform 0.3s ease;
        }

        .rotated {
          transform: rotate(180deg);
        }
      }

      .subMenuList {
        list-style: none;
        padding: 0;
        margin-top: 0.5rem;
        text-align: left;
        padding-left: 2rem;
        background-color: rgba(0, 123, 255, 0.02);

        li {
          padding: 0.8rem 1.5rem;
          transition: all 0.3s ease;

          &:hover {
            background-color: rgba(0, 123, 255, 0.05);
          }

          a {
            color: #2e2e2e;
            font-size: 14px;
            text-decoration: none;
            font-weight: 500;
            transition: all 0.3s ease;

            &:hover {
              color: $blue-header;
            }
          }
        }
      }
    }
  }
}

.company {
  cursor: default;
}
