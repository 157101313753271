@import '../../../../scss/utils/variables';

/* Background styles */
.background {
  position: relative;
  background: linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%);
  padding: 8rem 1rem;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 1px 1px, rgba(0, 132, 255, 0.03) 1px, transparent 0);
    background-size: 40px 40px;
    pointer-events: none;
  }
}

/* Container styles */
.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-top: 5rem;


  @media (max-width: 1024px) {
    width: 100%;
    // padding: 0 2rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    // padding: 0 1rem;
  }

  .tableContainer {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 132, 255, 0.1);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
    transition: all 0.3s ease;
    

    &:hover {
      box-shadow: 0 8px 32px rgba(0, 132, 255, 0.1);
      border-color: rgba(0, 132, 255, 0.2);
    }

    &.open {
      border-bottom: 1px solid rgba(0, 132, 255, 0.1);
    }
  }

  .titleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.8);

    &:hover {
      background: rgba(0, 132, 255, 0.05);
    }
  }

  .title {
    font-family: $font-family-header;
    font-size: 1.25rem;
    font-weight: 600;
    color: $blue-dark;
    margin: 0;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 30px;
      height: 2px;
      background: $blue-sub-header;
      opacity: 0.5;
    }

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  .indicator {
    font-size: 1.2rem;
    color: $blue-sub-header;
    transition: transform 0.3s ease;
  }

  .note {
    padding: 1rem 2rem;
    font-family: $font-family-header;
    font-size: 0.95rem;
    color: #666;
    line-height: 1.6;
    margin: 0;
    background: rgba(0, 132, 255, 0.02);
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    background: white;
  }

  .headerCell {
    padding: 1rem 2rem;
    font-family: $font-family-header;
    font-size: 0.9rem;
    font-weight: 600;
    color: $blue-dark;
    text-align: left;
    border-bottom: 1px solid rgba(0, 132, 255, 0.1);
    background: rgba(0, 132, 255, 0.02);
  }

  .cell {
    padding: 1rem 2rem;
    font-family: $font-family-header;
    font-size: 0.95rem;
    color: #4a4a4a;
    text-align: left;
    border-bottom: 1px solid rgba(0, 132, 255, 0.1);
    transition: all 0.3s ease;

    &:hover {
      background: rgba(0, 132, 255, 0.02);
    }
  }
}

/* Header styles */
.header {
  font-family: $font-family-header;
  font-size: 3rem;
  font-weight: 700;
  color: $blue-dark;
  margin-bottom: 2rem;
  position: relative;
  line-height: 1.2;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: $blue-sub-header;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  &.animate::after {
    transform: translateX(-50%) scaleX(1);
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
}

/* Subheader styles */
.subHeader {
  font-family: $font-family-header;
  font-size: 1.75rem;
  font-weight: 600;
  color: $blue-sub-header;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 2px;
    background: $blue-sub-header;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

/* Text styles */
.text {
  font-family: $font-family-header;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 3rem;
  max-width: 800px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.7;
  }
}

/* Tablet styles */
@media (max-width: 1024px) {
  .header {
    font-size: 2.5rem;
  }

  .subHeader {
    font-size: 1.5rem;
  }

  .text {
    font-size: 1rem;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .header {
    font-size: 2rem;
  }

  .subHeader {
    font-size: 1.25rem;
  }

  .text {
    font-size: 0.95rem;
  }

  .titleRow {
    padding: 1rem 1.5rem;
  }

  .headerCell,
  .cell {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
}
