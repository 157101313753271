@import '../../../scss/utils/variables';

.background {
  position: relative;
  background: linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%);
  padding: 8rem 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 1px 1px, rgba(0, 132, 255, 0.03) 1px, transparent 0);
    background-size: 40px 40px;
    pointer-events: none;
  }

  @media (max-width: 1024px) {
    padding-top: 6rem;
  }

  @media (max-width: 600px) {
    padding-top: 0rem 0;
    padding-bottom: 0;
  }

  .container {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 6rem;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 3rem;
      padding: 0 2rem;
    }

    @media (max-width: 768px) {
      padding: 0 1rem;
    }

    .col1 {
      flex: 1;
      max-width: 600px;
      position: relative;
      z-index: 2;
      background: rgba(255, 255, 255, 0.8);
      padding: 3rem;
      border-radius: 20px;
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.2);
      transform: translateY(0);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      @media (max-width: 1024px) {
        max-width: 100%;
        padding: 2rem;
      }
    }

    .col2 {
      flex: 1;
      position: relative;
      z-index: 1;
      transform: translateX(0);
      transition: transform 0.3s ease;

      img {
        max-width: 100%;
        height: auto;
        filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.02);
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 1024px) {
        position: absolute;
        right: -10%;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.1;
        z-index: 0;
      }
    }
  }
}

.header {
  font-family: $font-family-header;
  font-size: 3rem;
  font-weight: 700;
  color: $blue-dark;
  margin-bottom: 2rem;
  position: relative;

  // display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background: $blue-sub-header;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &.animate::after {
    transform: scaleX(1);
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
}

.subHeader {
  font-family: $font-family-header;
  font-size: 1.75rem;
  font-weight: 600;
  color: $blue-sub-header;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40px;
    height: 2px;
    background: $blue-sub-header;
    opacity: 0.5;
  }
}

.text {
  font-family: $font-family-header;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 2rem;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.7;
  }
}

.dedicatedServersButton {
  display: inline-block;
  padding: 0.6rem 1.5rem;
  border: 0.16rem solid $blue-sub-header;
  color: $blue-sub-header;
  text-transform: uppercase;
  font-family: $font-family-header;
  font-weight: 400;
  text-align: center;
  transition: all 0.15s;
}

// Animation classes
.fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fadeIn.visible {
  opacity: 1;
  transform: translateY(0);
}

.slideIn {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.slideIn.visible {
  opacity: 1;
  transform: translateX(0);
}

// Responsive styling for screens up to 768px
@media (max-width: 1024px) {
  .container {
    flex-direction: column; // Stack columns vertically
    padding: 2rem;
    gap: 2rem;

    .col1, .col2 {
      width: 100%; // Take full width

    }

    .col1 {
      margin-bottom: 2rem; // Add spacing between text and image
    }

    .text {
      max-width: 100%; // Full width for better readability
    }

    .col2 img {
      max-width: 80%;
      height: auto;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}


// Tablet styles
@media (max-width: 1024px) {
  .background .container .col1 .header {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .background .container .col1 .subHeader  {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .background .container .col1 .text  {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }


}

// Mobile styles
@media (max-width: 600px) {
  .background .container .col1 .header {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }
  .background .container .col1 .subHeader  {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .background .container .col1 .text {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }

}