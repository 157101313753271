@import "../../../../scss/utils/variables";

.background {
  position: relative;
  background: linear-gradient(to bottom, #f7f9fc 0%, #ffffff 100%);
  padding: 5rem 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  .allContentContainer {
    padding: 4rem 1rem;
  }

  .dedicatedServersContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 2rem;
    max-width: 1200px;
    padding: 0 1rem;
    flex-wrap: wrap;
    color: $blue-dark;
    text-align: left;
    margin-bottom: 3rem;

    @media (max-width: 800px) {
      flex-direction: column;
      text-align: center;
    }

    .dedicatedServersLeft {
      flex: 1;

      .dedicatedServersHeader {
        font-family: $font-family-header;
        font-size: 3rem;
        font-weight: 700;
        color: $blue-dark;
        margin-bottom: 1rem;
        text-align: left;

        @media (max-width: 800px) {
          text-align: center;
          font-size: 2.5rem;
        }
      }

      .dedicatedServersSubHeader {
        font-family: $font-family-header;
        font-size: 1.75rem;
        font-weight: 600;
        color: $blue-sub-header;
        margin-bottom: 1rem;
        
        @media (max-width: 800px) {
          text-align: center;
          font-size: 1.5rem;
        }
      }

      .dedicatedServersText {
        font-family: $font-family-header;
        font-size: 1.1rem;
        color: #666;
        margin-bottom: 1.5rem;
        line-height: 1.8;
        max-width: 800px;

        @media (max-width: 800px) {
          margin: 0 auto;
          text-align: center;
          font-size: 1rem;
          line-height: 1.7;
          margin-bottom: 1.5rem;
        }
      }

      .here {
        color: $blue-sub-header;
        text-decoration: none;
        font-weight: 600;
        transition: all 0.3s ease;
        padding: 0.5rem 1rem;
        background: rgba($blue-sub-header, 0.1);
        border-radius: 8px;
        display: inline-block;
        margin-top: 1rem;

        &:hover {
          background: rgba($blue-sub-header, 0.2);
          transform: translateY(-2px);
        }

        @media (max-width: 800px) {
          display: table;
          margin: 1rem auto;
        }
      }
    }
  }

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0rem;
    color: $blue-dark;
    position: relative;

    .colocationSpecs {
      width: 100%;

      .info {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        .faq {
          background: white;
          border-radius: 12px;
          overflow: hidden;
          width: 100%;
          transition: all 0.3s ease;
          max-height: 60px;
          cursor: pointer;
          border: 1px solid rgba($blue-header, 0.1);
          box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

          &.expanded {
            max-height: 500px;
            padding-bottom: 1rem;
            background: white;
            box-shadow: 0 8px 32px rgba(0, 132, 255, 0.1);
            border-color: rgba($blue-header, 0.2);
          }

          .faqHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.5rem;
            background: rgba(255, 255, 255, 0.9);

            &:hover {
              background: rgba(0, 132, 255, 0.05);
            }

            .leftContainer {
              display: flex;
              align-items: center;
              gap: 0.75rem;

              .icon {
                color: $blue-sub-header;
                font-size: 1.25rem;
              }

              .faqTitle {
                color: $blue-dark;
                font-family: $font-family-header;
                font-weight: 600;
                font-size: 1.25rem;
              }
            }

            .arrowIndicator {
              color: $blue-sub-header;
              font-size: 1.25rem;
              transition: transform 0.3s ease;
            }

            .arrowOpen {
              transform: rotate(180deg);
            }
          }

          .faqContent {
            list-style: none;
            padding: 0 1.5rem 1rem;
            margin: 0;
            opacity: 0;
            max-height: 0;
            min-height: 100%;
            transition: max-height 0.3s ease, opacity 0.3s ease;

            .listItem {
              display: flex;
              align-items: flex-start;
              gap: 1rem;
              color: #666;
              font-family: $font-family-header;
              font-size: 0.95rem;
              font-weight: 500;
              margin-top: 1.25rem;
              line-height: 1.6;

              .checkIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 50%;
                font-size: 0.75rem;
                color: $blue-sub-header;
                background: rgba($blue-sub-header, 0.1);
                flex-shrink: 0;
              }
            }
          }

          &.expanded .faqContent {
            max-height: 515px;
            opacity: 1;
          }
        }
      }
    }
  }
}

.selectorContainerContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 2rem;

  .selectorContainer {
    position: relative;
    border-radius: 16px;
    display: flex;
    color: $blue-dark;
    text-align: center;
    justify-content: space-around;
    align-items: stretch;
    z-index: 0;
    overflow: hidden;
    background: white;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border: 1px solid rgba($blue-header, 0.1);
  }

  .ogden,
  .losangeles {
    width: 100%;
    padding: 1.25rem;
    text-align: center;
    cursor: pointer;
    position: relative;
    color: #666;
    font-family: $font-family-header;
    font-weight: 600;
    transition: all 0.3s ease;

    &.active {
      color: $blue-dark;
      background-color: rgba($blue-sub-header, 0.05);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 10%;
        width: 80%;
        height: 3px;
        background: $blue-sub-header;
        border-radius: 10px;
      }
    }

    &:hover:not(.active) {
      color: $blue-sub-header;
      background-color: rgba($blue-sub-header, 0.02);
    }
  }

  .ogden {
    border-radius: 16px 0 0 16px;
  }
}
