@import "../../../../scss/utils/variables";

.background {
  width: 100%;
  background: linear-gradient(to bottom, #f7f9fc 0%, #ffffff 100%);
  padding: 5rem 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba($blue-header, 0.1), transparent);
  }
}

.servicesHeaderContainer {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 3rem;

  .header {
    font-family: $font-family-header;
    font-size: 2.5rem;
    font-weight: 700;
    color: $blue-dark;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .subHeader {
    font-family: $font-family-header;
    font-size: 1.5rem;
    font-weight: 600;
    color: $blue-sub-header;
    margin-bottom: 1.5rem;
  }

  .text {
    font-family: $font-family-header;
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    max-width: 70%;

    @media (max-width: 1024px) {
      max-width: 80%;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }

    strong {
      color: $blue-dark;
      font-weight: 600;
    }
  }
}

.servicesContainer {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;

  .cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    a {
      text-decoration: none;
    }

    .card {
      background: white;
      padding: 2.5rem;
      border-radius: 16px;
      position: relative;
      transition: all 0.3s ease;
      border: 1px solid rgba($blue-header, 0.1);
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(to right, $blue-header, $blue-sub-header);
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 32px rgba($blue-sub-header, 0.1);
        border-color: rgba($blue-sub-header, 0.2);
      }

      .cardTopRow {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-bottom: 2rem;

        .image {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }

        .cardHeaderContainer {
          .cardHeader {
            font-family: $font-family-header;
            font-size: 1.5rem;
            font-weight: 700;
            color: $blue-dark;
            margin-bottom: 0.5rem;
          }

          .cardSubHeader {
            font-family: $font-family-header;
            font-size: 1.1rem;
            font-weight: 500;
            color: $blue-sub-header;
          }
        }
      }

      .cardText {
        font-family: $font-family-header;
        font-size: 0.95rem;
        color: #666;
        line-height: 1.6;
        margin-bottom: 2rem;
      }

      .cardLearnMore {
        font-family: $font-family-header;
        font-size: 1rem;
        font-weight: 600;
        color: $blue-sub-header;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.3s ease;

        &:hover {
          color: darken($blue-sub-header, 10%);
          transform: translateX(5px);
        }
      }
    }
  }
}

    // Tablet styles
    @media (max-width: 1024px) {
        .servicesHeaderContainer .header,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardHeader {
          font-size: $header-font-size-tablet;
          font-weight: $header-font-weight-tablet;
          margin-bottom: $header-bottom-gap-tablet;
        }
    
        .servicesHeaderContainer .subHeader,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardSubHeader {
          font-size: $subheader-font-size-tablet;
          font-weight: $subheader-font-weight-tablet;
          margin-bottom: $subheader-bottom-gap-tablet;
        }
    
        .servicesHeaderContainer .text,
        .servicesContainer .cardContainer .card .cardText {
          font-size: $text-font-size-tablet;
          line-height: $text-line-height-tablet;
          margin-bottom: $text-bottom-gap-tablet;
        }
      }
    
      // Mobile styles
      @media (max-width: 600px) {
        .servicesHeaderContainer .header,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardHeader {
          font-size: $header-font-size-mobile ;
          font-weight: $header-font-weight-mobile;
          margin-bottom: $header-bottom-gap-mobile;
        }
    
        .servicesHeaderContainer .subHeader,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardSubHeader  {
          font-size: $subheader-font-size-mobile;
          font-weight: $subheader-font-weight-mobile;
          margin-bottom: $subheader-bottom-gap-mobile;
        }
    
        .servicesHeaderContainer  .text,
        .servicesContainer .cardContainer .card .cardText {
          font-size: $text-font-size-mobile;
          line-height: $text-line-height-mobile;
          margin-bottom: $text-bottom-gap-mobile;
        }
      }