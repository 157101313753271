@import "../../scss/utils/variables";

.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1400px;
}

.dedicatedServersContainer {
  max-width: 100%;
  margin: 6rem auto;
  height: 100%;
  color: white;
  background-color: $blue-tint;
  border-radius: 16px;
  padding: 4rem;
  position: relative;

  @media (max-width: 1200px) {
    padding: 3rem;
    margin: 4rem auto;
  }

  @media (max-width: 768px) {
    padding: 0rem;
    margin: 4rem auto;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

  &.reverse {
    flex-direction: row-reverse;
  }

  @media (max-width: 1200px) {
    gap: 3rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;

    &.reverse {
      flex-direction: column;
    }
  }
}

.textSection {
  flex: 1;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  .dedicatedServersHeader {
    font-family: $font-family-header;
    font-size: 2.5rem;
    font-weight: 400;
    color: $header-color;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .dedicatedServersSubHeader {
    font-family: $font-family-header;
    font-size: 1.5rem;
    font-weight: 300;
    color: $blue-sub-header;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .dedicatedServersText {
    font-family: $font-family-header;
    font-size: 1rem;
    color: #000000;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
}

.imageSection {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  .serverImage {
    max-width: 100%;
    max-height: 800px;
    height: auto;
    object-fit: contain;
    border-radius: 12px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }
  }
}

.button {
  display: inline-block;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border: 2px solid $blue-sub-header;
  color: $blue-sub-header;
  font-family: $font-family-header;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: white;

  &:hover {
    background: $blue-sub-header;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
} 