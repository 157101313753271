@import '../../../../scss/utils/variables';

.container {
    position: relative;
    background: linear-gradient(to bottom, #f7f9fc 0%, #ffffff 100%);
    padding: 4rem 0;
    overflow: hidden;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
    }

    .topSection {
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 3rem;

        .dedicatedServersHeader {
            font-family: $font-family-header;
            font-size: 2.5rem;
            font-weight: 700;
            color: $blue-dark;
            margin-bottom: 1rem;
            position: relative;
            line-height: 1.2;

            &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 50px;
                height: 3px;
                background: $blue-sub-header;
                transform: scaleX(0);
                transform-origin: center;
                transition: transform 0.3s ease;
            }

            &.animate::after {
                transform: translateX(-50%) scaleX(1);
            }

            @media (max-width: 768px) {
                font-size: 2rem;
                text-align: center;
            }
        }

        .dedicatedServersSubHeader {
            font-family: $font-family-header;
            font-size: 1.5rem;
            font-weight: 600;
            color: $blue-sub-header;
            margin-bottom: 1.5rem;
            position: relative;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 50%;
                transform: translateX(-50%);
                width: 40px;
                height: 2px;
                background: $blue-sub-header;
                opacity: 0.5;
            }

            @media (max-width: 768px) {
                font-size: 1.25rem;
                text-align: center;
                width: 100%;
            }
        }

        .dedicatedServersText {
            font-family: $font-family-header;
            font-size: 1rem;
            line-height: 1.6;
            color: #4a4a4a;
            margin: 0 auto;
            max-width: 800px;

            a {
                color: $blue-sub-header;
                text-decoration: none;
                font-weight: 600;
                transition: all 0.3s ease;

                &:hover {
                    color: darken($blue-sub-header, 10%);
                }
            }

            @media (max-width: 768px) {
                font-size: 0.95rem;
                line-height: 1.5;
                text-align: center;
                padding: 0 1rem;
            }
        }
    }

    .cardContainer {
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
        padding: 0 1rem;

        @media (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        .card {
            background: white;
            border-radius: 12px;
            padding: 2rem;
            border: 1px solid rgba(0, 132, 255, 0.1);
            box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 8px 32px rgba(0, 132, 255, 0.1);
                border-color: rgba(0, 132, 255, 0.2);
            }

            .cardTopRow {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 1.5rem;
                
                h3 {
                    font-family: $font-family-header;
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: $blue-dark;
                    line-height: 1.4;
                }

                .networkIcon {
                    width: 28px;
                    height: 28px;
                }
            }

            .circleAndState {
                width: 100%;
                height: auto;
                margin-bottom: 1.5rem;
            }

            .testLink {
                display: inline-block;
                width: 100%;
                padding: 0.875rem 0;
                text-align: center;
                background: $blue-sub-header;
                color: white;
                font-family: $font-family-header;
                font-weight: 600;
                font-size: 0.95rem;
                border-radius: 6px;
                transition: all 0.3s ease;
                text-decoration: none;
                border: none;

                &:hover {
                    background: darken($blue-sub-header, 5%);
                    transform: translateY(-2px);
                }

                &:active {
                    transform: translateY(0);
                }
            }
        }
    }
}

// Tablet styles
@media (max-width: 1024px) {
    .container .topSection .dedicatedServersHeader {
      font-size: 2.25rem;
      margin-bottom: 0.75rem;
    }
  
    .container .topSection .dedicatedServersSubHeader {
      font-size: 1.35rem;
      margin-bottom: 1.25rem;
    }
  
    .container .topSection .dedicatedServersText {
      font-size: 0.95rem;
      line-height: 1.5;
    }
}
  
// Mobile styles
@media (max-width: 600px) {
    .container {
        padding: 3rem 0;
    }

    .container .topSection .dedicatedServersHeader {
      font-size: 1.75rem;
      margin-bottom: 0.5rem;
    }

    .container .topSection .dedicatedServersSubHeader {
      font-size: 1.15rem;
      margin-bottom: 1rem;
    }
  
    .container .topSection .dedicatedServersText {
      font-size: 0.9rem;
      line-height: 1.4;
      margin-bottom: 1.5rem;
    }
}