@import '../../../../scss/utils/variables';

.section {
  position: relative;
  background: linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%);
  padding: 8rem 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 1px 1px, rgba(0, 132, 255, 0.03) 1px, transparent 0);
    background-size: 40px 40px;
    pointer-events: none;
  }
}

.container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.textContent {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.title {
  font-family: $font-family-header;
  font-size: 3rem;
  font-weight: 700;
  color: $blue-dark;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: $blue-sub-header;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  &.animate::after {
    transform: translateX(-50%) scaleX(1);
  }
}

.subtitle {
  font-family: $font-family-header;
  font-size: 1.75rem;
  font-weight: 600;
  color: $blue-sub-header;
  margin-bottom: 1.5rem;
}

.description {
  font-family: $font-family-header;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4a4a4a;
  max-width: 600px;
  margin: 0 auto;
}

.reviewsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.reviewCard {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  }
}

.reviewHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stars {
  display: flex;
  gap: 0.25rem;
}

.star {
  color: #FFD700;
  font-size: 1.25rem;
}

.reviewerInfo {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.reviewerName {
  font-family: $font-family-header;
  font-size: 1.25rem;
  font-weight: 600;
  color: $blue-dark;
}

.reviewDate {
  font-family: $font-family-header;
  font-size: 0.9rem;
  color: #666;
}

.reviewText {
  font-family: $font-family-header;
  font-size: 1rem;
  line-height: 1.6;
  color: #4a4a4a;
}

// Animation classes
.fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fadeIn.visible {
  opacity: 1;
  transform: translateY(0);
}

// Responsive styles
@media (max-width: 1024px) {
  .section {
    padding: 6rem 0;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .reviewsGrid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .section {
    padding: 4rem 0;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .reviewCard {
    padding: 1.5rem;
  }

  .reviewsGrid {
    grid-template-columns: 1fr;
  }
} 