@import '../../../../scss/utils/variables';

.background {
  width: 100%;
  background-color: $blue-tint;

  .container {
    width: 80%;
    margin: 10rem auto;
    margin-top: 15rem;
    display: flex;
    gap: 5rem;
    max-width: 1400px;

    @media (max-width: 768px) {
        flex-wrap: wrap;

    }


    .col2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .group1 {
            margin-bottom: 3rem;
            .header {
              font-family: $font-family-header;
              font-size: $header-font-size;
              font-weight: $header-font-weight;
              color: $header-color;
              margin-bottom: $header-bottom-gap;
              }
            
              .text {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin-bottom: $text-bottom-gap;
                color: #121214;
                width: 80%;
              }

              .submitSupportTicketContainer {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin: 1.5rem 0;

                .plane {
                    width: 30px;
                }

                .submitSupportTicket {
                    font-family: $font-family-header;
                    font-size: 14px;
                    color: #121214;
                    margin-bottom: .5rem;
                    font-weight: 600;

                    @media (max-width: 1024px) {
                      font-size: 14px;
                    }
          
                    @media (max-width: 600px) {
                      font-size: 12px;
                    }
                }
              }
              
        }

        .group2 {
            .header {
                font-family: $font-family-header;
                font-size: 32px;
                font-weight: 600;
                color: $blue-header;
                margin-bottom: 1rem;
              }

              .contactGrid {
                display: grid;
                grid-template-columns: repeat(2, 1fr); // 2x2 grid layout
                gap: 2rem;
      
                @media (max-width: 1224px) {
                  grid-template-columns: 1fr; // Stacks items on smaller screens
                }

                .contactGroup {

                  .subHeader {
                      font-family: $font-family-header;
                      font-size: 20px;
                      font-weight: 400;
                      color: $blue-sub-header;
                      margin-bottom: .5rem;
                    }
  
                    .emailGroup, .phoneGroup {
                      display: flex;
                      gap: 1rem;
                      align-items: start;
                      margin-bottom: .5rem;
                      justify-content: start;
                      

                    @media (max-width: 1024px) {
                      img {
                        width: 20px;
                      } 
                    }
  
                      p {
                          font-family: $font-family-header;
                          font-size: 16px;
                          color: #121214;
                        }
                      
                        .address {
                          margin: .5rem 0;
                        }
                    }
  
                    
                    
                }
              }
              
        

        
        }
    }
  }
}

span {
  font-weight: 600;
}



// Tablet styles
@media (max-width: 1024px) {
  .background .container .col2 .group1 .header,
  .background .container .col2 .group2 .header {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
    margin-bottom: .5rem;
  }

  .background .container .col1 .col1Content .subHeader,
  .background .container .col2 .group2 .contactGrid .contactGroup .subHeader  {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .background .container .col2 .group1 .text,
  .background .container .col2 .group2 .contactGrid .contactGroup .emailGroup p,
  .background .container .col2 .group2 .contactGrid .contactGroup .phoneGroup p  {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }


}

// Mobile styles
@media (max-width: 600px) {
  .background .container .col2 .group1 .header,
  .background .container .col2 .group2 .header  {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }
  .background .container .col1 .col1Content  .subHeader,
  .background .container .col2 .group2 .contactGrid .contactGroup .subHeader  {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .background .container .col2 .group1 .text  {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
    margin-bottom: .5rem;

  }

}