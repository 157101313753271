@import '../../../scss/utils/variables';

.background {
    background-color: #f7f9fc;
    width: 100%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.2), transparent);
        box-shadow: 0 0 10px rgba(0, 132, 255, 0.1);
    }
}

.container {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    padding: 5rem 0;
    text-align: left;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: radial-gradient(circle at 1px 1px, rgba(0, 132, 255, 0.03) 1px, transparent 0);
        background-size: 40px 40px;
        pointer-events: none;
    }

    .col1, .col2, .col3 {
        align-self: flex-start;
        position: relative;
        z-index: 1;
    }

    .col1 {
        img {
            width: 200px;
            margin-bottom: 1.5rem;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }
        
        .text {
            font-family: $font-family-header;
            font-size: $text-font-size;
            color: #121214;
            margin-top: 0.5rem;
            width: 80%;
            line-height: 1.6;
            opacity: 0.9;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 1;
            }

            @media (max-width: 1024px) {
                font-size: $text-font-size-tablet;
            }

            @media (max-width: 600px) {
                font-size: $text-font-size-mobile;
            }
        }
    }

    .col2 {
        h4 {
            font-family: $font-family-header;
            font-size: 1.5rem;
            font-weight: 700;
            color: $blue-dark;
            margin-bottom: 1.5rem;
            position: relative;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 40px;
                height: 2px;
                background: $blue-sub-header;
                transition: width 0.3s ease;
            }

            &:hover::after {
                width: 60px;
            }
        }

        ul {
            padding: 0;
            list-style-type: none;

            li {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin-bottom: 1rem;

                a {
                    color: #121214;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    position: relative;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background: $blue-sub-header;
                        transition: width 0.3s ease;
                    }

                    &:hover {
                        color: $blue-sub-header;
                        transform: translateX(5px);

                        &::after {
                            width: 100%;
                        }
                    }

                    @media (max-width: 1024px) {
                        font-size: $text-font-size-tablet;
                    }
    
                    @media (max-width: 600px) {
                        font-size: $text-font-size-mobile;
                    }
                }
            }
        }
    }

    .col3 {
        h4 {
            font-family: $font-family-header;
            font-size: 1.5rem;
            font-weight: 700;
            color: $blue-dark;
            margin-bottom: 1.5rem;
            position: relative;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 40px;
                height: 2px;
                background: $blue-sub-header;
                transition: width 0.3s ease;
            }

            &:hover::after {
                width: 60px;
            }
        }

        .contactItem {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            margin-bottom: 1.5rem;
            padding: 0.5rem 0;
            transition: transform 0.3s ease;

            &:hover {
                transform: translateX(5px);
            }

            .contactIcon {
                font-size: 1.5rem;
                color: $blue-sub-header;
                margin-top: 0.25rem;
                transition: transform 0.3s ease;
            }

            &:hover .contactIcon {
                transform: scale(1.1);
            }

            .contactInfoText {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin: 0;
                color: #121214;
                opacity: 0.9;
                transition: opacity 0.3s ease;

                &:hover {
                    opacity: 1;
                }

                @media (max-width: 1024px) {
                    font-size: $text-font-size-tablet;
                }

                @media (max-width: 600px) {
                    font-size: $text-font-size-mobile;
                }
            }
        }
    }
}

// Tablet styles
@media (max-width: 1024px) {
    .container {
        width: 90%;
        gap: 2rem;
        padding: 4rem 0;
    }
}

// Mobile styles
@media (max-width: 600px) {
    .container {
        width: 95%;
        gap: 2.5rem;
        padding: 3rem 0;
    }
}
