@import '../../../../scss/utils/variables';

.background {
  padding: 4rem 0 0 0;
  margin-top: 0rem;

  
  @media (max-width: 1024px) {
    margin-top: 0rem;
  }

  @media (max-width: 800px) {
    margin-top: 0rem;
    padding-top: 1rem;
  }

  .selectBarContainer {
    width: 80%;
    margin: 0 auto;
    max-width: 1400px;

    .selectBar {
      background-color: #F0F0F0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem;
      border-radius: 5px;

      p {
        font-family: $font-family-header;
        font-size: 24px;
        font-weight: 400;
        color: $blue-sub-header;
        cursor: pointer;
        padding: 0.5rem 1.5rem;
        border-radius: 8px;
        transition: all 0.3s ease;

        @media (max-width: 1024px) {
          font-size:  22px;
        }

        @media (max-width: 700px) {
          font-size:  18px;
        }

        &:hover {
          color: darken($blue-sub-header, 10%);
        }
      }

      .active {
        color: $blue-header;
        background-color: white;
        font-weight: 600;
        border: 1px solid lighten($blue-header, 40%);
      }
    }
  }

  .container {
    width: 80%;
    margin: 1rem auto 4rem;
    margin-top: 5rem;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-family: $font-family-header;
      font-size: 32px;
      font-weight: 600;
      color: $blue-header;
      margin-bottom: 2rem;
      text-align: left;
      width: 100%;
    }

    .colContainer {
      display: flex;
      flex-direction: row;  // Start with row layout
      align-items: flex-start;
      gap: 5rem;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;  // Stack columns on smaller screens
        gap: 2rem;
      }

      .col1,
      .col2 {
        flex: 1;

        .subTitle {
          font-family: $font-family-header;
          font-size: $subheader-font-size;
          font-weight: $subheader-font-weight;
          color: $blue-sub-header;
          margin-bottom: $subheader-bottom-gap;
        }

        .text {
          font-family: $font-family-header;
          font-size: $text-font-size;
          margin-bottom: $text-bottom-gap;
          color: #121214;
          line-height: 1.5;
          max-width: 100%;  // Allow full width on small screens

          @media (max-width: 768px) {

          }
        }

        .listTitle {
          font-family: $font-family-header;
          font-size: 18px;
          font-weight: 700;
          color: $blue-header;
          margin-bottom: 1rem;

          
        @media (max-width: 1024px) {
          margin-top: 2rem;
          font-size:  17px;
        }

        @media (max-width: 700px) {
          font-size:  16px;
        }
        }

        ul.featureList {
          list-style-type: disc;
          padding-left: 20px;

          .featuredText {
            font-family: $font-family-header;
            font-size: 16px;
            color: #121214;
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 1rem;

            
        @media (max-width: 1024px) {
          font-size:  15px;
        }

        @media (max-width: 700px) {
          font-size:  14px;
        }

            strong {
              color: $blue-header;
            }

          }
        }
      }
    }
  }
}

// Adjustments for Select Bar
@media (max-width: 768px) {
  .selectBarContainer {
    .selectBar {
      flex-direction: column;
      gap: 0.5rem;
    }

    p {

    }
  }
}


// Tablet styles
@media (max-width: 1024px) {
  .background .container .title {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .background .container .colContainer .col1 .subTitle,
  .background .container .colContainer .col2 .subTitle  {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .background .container .colContainer .col1 .text,
  .background .container .colContainer .col2 .text {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }


}

// Mobile styles
@media (max-width: 600px) {
  .background .container .title {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }
  .background .container .colContainer .col1 .subTitle,
  .background .container .colContainer .col2 .subTitle  {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .background .container .colContainer .col1 .text,
  .background .container .colContainer .col2 .text  {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }

}
