.container {
    position: relative;
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .grid {
    display: flex;
    position: relative;
    gap: 10px;
    overflow: hidden;
  }
  
  .gridItem {
    flex: 1 0 45%; // Adjust size for two images in view and one partially
    overflow: hidden;
    position: relative;
  }
  
  .image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .arrowLeft,
  .arrowRight {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
    border: none;
    cursor: pointer;
    transform: translateY(-50%);
    padding: 0.5rem 1rem;
    z-index: 10;
  }
  
  .arrowLeft {
    left: 10px;
  }
  
  .arrowRight {
    right: 10px;
  }
  