@import '../../../scss/utils/variables';

.section {
  padding: 8rem 0;
  background: linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 1px 1px, rgba(0, 132, 255, 0.03) 1px, transparent 0);
    background-size: 40px 40px;
    pointer-events: none;
  }
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    // padding: 0 1rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.textContent {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  position: relative;

  .title {
    font-family: $font-family-header;
    font-size: 2.5rem;
    font-weight: 700;
    color: $blue-dark;
    margin-bottom: 1rem;
    line-height: 1.2;
    position: relative;
    // display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background: $blue-sub-header;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.3s ease;
    }

    &.animate::after {
      transform: translateX(-50%) scaleX(1);
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .subtitle {
    font-family: $font-family-header;
    font-size: 1.5rem;
    font-weight: 600;
    color: $blue-sub-header;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 2px;
      background: $blue-sub-header;
      opacity: 0.5;
    }

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .description {
    font-family: $font-family-header;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.featureCard {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 132, 255, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, $blue-sub-header, $blue-header);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 32px rgba(0, 132, 255, 0.1);
    border-color: rgba(0, 132, 255, 0.2);

    &::before {
      transform: scaleX(1);
    }

    .icon {
      transform: scale(1.1) rotate(5deg);
    }
  }

  .iconWrapper {
    width: 64px;
    height: 64px;
    background: linear-gradient(135deg, rgba(0, 132, 255, 0.1), rgba(0, 132, 255, 0.05));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
  }

  .icon {
    font-size: 1.75rem;
    color: $blue-sub-header;
    transition: all 0.3s ease;
  }

  .featureTitle {
    font-family: $font-family-header;
    font-size: 1.25rem;
    font-weight: 600;
    color: $blue-dark;
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 2px;
      background: $blue-sub-header;
      opacity: 0.5;
    }
  }

  .featureDescription {
    font-family: $font-family-header;
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    max-width: 250px;
    margin: 0 auto;
  }
}

// Animation classes
.fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fadeIn.visible {
  opacity: 1;
  transform: translateY(0);
}

.slideIn {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.slideIn.visible {
  opacity: 1;
  transform: translateX(0);
}

// Stagger animation for cards
@for $i from 1 through 6 {
  .featureCard:nth-child(#{$i}) {
    transition-delay: #{$i * 0.1}s;
  }
}