@import '../../scss/utils/variables';

.container {
    width: 100%;
    margin: 0;
    background: white;
    padding: 6rem 0 4rem;
    position: relative;
    z-index: 1;
    // min-height: 100vh;
}

.mainContent {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    color: black;
    position: relative;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    background: white;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

    @media (max-width: 1200px) {
        width: 90%;
        padding: 1.5rem;
    }

    @media (max-width: 768px) {
        // width: 95%;
        padding: 1rem;
    }
}

.titleSection {
    text-align: center;
    margin-bottom: 3rem;
    padding: 0 1rem;

    h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: $blue-dark;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }

    p {
        font-size: 1.1rem;
        color: #666;
        max-width: 800px;
        margin: 0 auto;
        line-height: 1.6;

        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }
}

.filterHeader {
    display: flex;
    align-items: stretch;
    gap: 1.5rem;
    margin-top: 1rem;
    padding: 1.5rem;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        margin: 0 1rem 1.5rem;
    }
}

.filterButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.875rem 1.5rem;
    background: white;
    border: 2px solid $blue-sub-header;
    border-radius: 12px;
    color: $blue-sub-header;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    height: 100%;
    min-height: 60px;
    min-width: 140px;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 25px rgba(0, 0, 0, 0.08);
        background: $blue-sub-header;
        color: white;
    }

    &:active {
        transform: translateY(0);
    }

    img {
        width: 20px;
        height: 20px;
        filter: brightness(0) saturate(100%) invert(31%) sepia(98%) saturate(1111%) hue-rotate(182deg) brightness(96%) contrast(107%);
        transition: all 0.3s ease;
    }

    &:hover img {
        filter: brightness(0) invert(1);
        transform: rotate(180deg);
    }

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        min-height: unset;
    }
}

.activeFiltersContainer {
    max-width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 132, 255, 0.1);

    @media (max-width: 768px) {
        max-width: 100%;
        flex-direction: column;
    }
}

.activeFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: .5rem;
    font-size: 14px;

    .activeFilterGroup {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .activeFilterHeader {
            font-weight: 600;
            color: $blue-dark;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin-bottom: 2px;
        }

        .activeFilter {
            display: inline-flex;
            align-items: center;
            padding: 6px 12px;
            background: #f0f7ff;
            border: 1px solid rgba(0, 132, 255, 0.15);
            border-radius: 8px;
            color: $blue-dark;
            font-weight: 500;
            transition: all 0.2s ease;
            box-shadow: 0 1px 2px rgba(0, 132, 255, 0.05);

            &:hover {
                background: #e1f0ff;
                border-color: rgba(0, 132, 255, 0.25);
                transform: translateY(-1px);
                box-shadow: 0 2px 4px rgba(0, 132, 255, 0.1);
            }
        }
    }
}

.clearAll {
    color: $blue-sub-header;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    padding: 6px 12px;
    border-radius: 6px;
    transition: all 0.2s ease;
    background: rgba($blue-sub-header, 0.04);

    &:hover {
        color: darken($blue-sub-header, 10%);
        background: rgba($blue-sub-header, 0.08);
        transform: translateY(-1px);
    }
}

.tableWrapper {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 1rem 0;
    padding-bottom: 1rem;
    padding-right: 1rem;

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &:last-child {
        margin-bottom: 2rem;
    }
}

.tableContainer {
    width: 100%;
    min-width: 950px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .flexHeader, .flexRow {
        display: grid;
        grid-template-columns: 1.2fr 0.8fr 1fr 0.8fr 0.6fr 0.6fr 0.8fr 0.8fr;
        align-items: center;
        padding-right: 1rem;

        @media (max-width: 1200px) {
            grid-template-columns: 1.2fr 0.8fr 1fr 0.8fr 0.6fr 0.6fr 0.8fr 0.8fr;
        }

        @media (max-width: 1050px) {
            grid-template-columns: 1.2fr 0.8fr 1fr 0.8fr 0.6fr 0.6fr 0.8fr 0.8fr;
        }
    }

    .flexHeader {
        background-color: $blue-dark;
        color: white;
        padding: 1.2rem 1rem;
        font-size: 18px;
        font-weight: 700;
        position: sticky;
        top: 0;
        z-index: 1;

        .flexCell {
            padding: 0 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media (max-width: 1200px) {
            font-size: 15px;
            padding: 1rem;
        }
    }

    .flexRow {
        padding: 1rem;
        font-size: 15px;
        font-family: $font-family-header;
        font-weight: 500;
        border-bottom: 1px solid #eee;
        transition: background-color 0.2s ease;

        &:nth-child(even) {
            background-color: #f7f7f7;
        }

        &:hover {
            background-color: #f0f7ff;
        }

        .flexCell {
            padding: 0 0.5rem;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                font-weight: 600;
                font-size: 15px;
                line-height: 1.4;

                @media (max-width: 1200px) {
                    font-size: 12px;
                }
            }

            &:nth-last-child(2) {
                text-align: left;
                padding-left: 1rem;
            }

            &:last-child {
                text-align: center;
                padding: 0 0.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @media (max-width: 1200px) {
            font-size: 13px;
        }
    }

    .loaderRow,
    .errorRow,
    .noDataRow {
        padding: 2rem;
        text-align: center;
        font-size: 16px;
        color: #666;
    }
}

.priceContainer {
    color: #28a745;
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
}

.orderNowButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.5rem 1rem;
    color: white;
    font-family: $font-family-header;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    background: linear-gradient(135deg, #0084ff 0%, #0052cc 100%);
    border-radius: 8px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 132, 255, 0.2);
    white-space: nowrap;
    width: fit-content;
    min-width: 90px;

    @media (max-width: 1200px) {
        padding: 0.5rem 0.75rem;
        font-size: 13px;
        min-width: 80px;
    }

    @media (max-width: 1050px) {
        padding: 0.4rem 0.6rem;
        font-size: 12px;
        min-width: 70px;
        gap: 0.2rem;
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 132, 255, 0.3);
        background: linear-gradient(135deg, #0095ff 0%, #0063ee 100%);
    }

    &:active {
        transform: translateY(0);
    }

    &::after {
        content: '→';
        transition: transform 0.2s ease;

        @media (max-width: 1050px) {
            display: none;
        }
    }

    &:hover::after {
        transform: translateX(4px);
    }
}

.noActiveFilters {
    width: 100%;
    padding: 1.25rem;
    background: #f8f9fa;
    border: 1px dashed #e9ecef;
    border-radius: 8px;
    color: #6c757d;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
        background: #f3f4f6;
        border-color: #dee2e6;
    }

    &::before {
        content: '🔍';
        font-size: 16px;
    }
}

// Pagination styles update
.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    padding: 1rem;

    button {
        min-width: 40px;
        height: 40px;
        padding: 0 1rem;
        border: 1px solid #ddd;
        background: white;
        color: $blue-dark;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover:not(:disabled) {
            background-color: #f0f7ff;
            border-color: #0084ff;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &.active {
            background-color: #0084ff;
            border-color: #0084ff;
            color: white;
        }
    }
}

// Records per page dropdown
.recordsPerPage {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: auto;

    label {
        font-size: 14px;
        color: $blue-dark;
    }

    select {
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        color: $blue-dark;
        background-color: white;
        cursor: pointer;

        &:focus {
            outline: none;
            border-color: #0084ff;
        }
    }
}
