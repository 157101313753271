@import '../../../../scss/utils/variables';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.3s ease-out;

  @media (max-width: 768px) {
    width: 95%;
    margin: 10px;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modalHeader {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: $blue-dark;
    font-size: 24px;
    font-weight: 500;
  }
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px;
  
  &:hover {
    color: $blue-dark;
  }
}

.modalContent {
  padding: 20px;
}

.priceFilter {
  margin-bottom: 30px;

  h3 {
    color: $blue-dark;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
  }
}

.priceSliderContainer {
  padding: 0 10px;
}

.priceLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: $blue-dark;
  font-weight: 500;
}

.slider {
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  height: 2px;
  background: $blue-sub-header;
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: white;
    border: 2px solid $blue-sub-header;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: $blue-sub-header;
    }
  }

  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: white;
    border: 2px solid $blue-sub-header;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: $blue-sub-header;
    }
  }
}

.filterOptionsContainer {
  .filterItem {
    margin: 10px 0;

    .filterOptionsItemClosed {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      cursor: pointer;
      border-radius: 6px;
      transition: all 0.3s ease;
      background-color: #f7f7f7;

      &:hover {
        background-color: #eee;
      }

      &.active {
        background-color: $blue-sub-header;
        color: white;

        img {
          filter: brightness(0) invert(1);
        }
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
      }

      img {
        width: 16px;
        height: 16px;
        transition: transform 0.3s ease;
        filter: brightness(0) saturate(100%) invert(31%) sepia(98%) saturate(1111%) hue-rotate(182deg) brightness(96%) contrast(107%);
      }
    }

    .filterOptionsItemOpen {
      background-color: white;
      border: 1px solid #eee;
      border-top: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow: hidden;
      max-height: 0;
      transition: all 0.3s ease;

      &.expanded {
        max-height: 200px;
        padding: 15px;
      }

      .dropdownList {
        list-style: none;
        padding: 0;
        margin: 0;

        .dropdownItem {
          margin: 8px 0;

          .dropdownLabel {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-size: 14px;
            color: $blue-dark;

            .filterOption {
              display: flex;
              align-items: center;
              gap: 0.75rem;
              padding: 0.75rem;
              cursor: pointer;
              transition: all 0.2s ease;
              border-radius: 8px;

              &:hover {
                background: #f0f7ff;
              }

              input[type="checkbox"] {
                width: 18px;
                height: 18px;
                margin: 0;
                cursor: pointer;
                position: relative;
                border: 2px solid #0084ff;
                border-radius: 4px;
                background-color: white;
                transition: all 0.2s ease;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;

                &:checked {
                  background-color: #0084ff;
                  border-color: #0084ff;

                  &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    width: 4px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                  }
                }

                &:focus {
                  outline: none;
                  box-shadow: 0 0 0 2px rgba(0, 132, 255, 0.2);
                }
              }

              label {
                flex: 1;
                font-size: 14px;
                color: #333;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.modalFooter {
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.applyButton {
  background-color: $blue-sub-header;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: darken($blue-sub-header, 10%);
  }
} 