@import '../../../scss/utils/variables';

.fullWidthBackground {
    background-color: $blue-dark;
    color: $white;

    .container {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        p {

            padding: 1rem 0;

            a {
                color: white;
            }
    
        }
    }
}


