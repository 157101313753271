@import '../../../../scss/utils/variables';


.carouselContainer {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 150px; // Set a fixed height for visibility
    margin-top: 10rem;

    
  @media (max-width: 600px) {
    margin-top: 5rem;

  }
  }
  
  .carouselTrack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    width: max-content; // Automatically adjust to the content's width
    animation: slide 30s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  