@import '../../../../../../scss/utils/variables';

.carouselText {
  font-family: $font-family-oswold;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 48px;

  @media (max-width: 600px) {
    font-size: 36px;

  }
}
  