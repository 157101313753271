@import "../../scss/utils/variables";

.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1400px;
  margin-top: 10rem;

  @media (max-width: 450px) {
    margin-top: 6rem;
  }
}

.dedicatedServersContainer {
  width: 100%;
  margin: 0rem auto;
  height: 100%;
  color: white;
  background-color: $blue-tint;
  border-radius: 16px;
  padding: 4rem 0;
  position: relative;

  @media (max-width: 1200px) {
    padding: 3rem 0;
  }

  @media (max-width: 768px) {
    padding: 2rem 0;
    margin: 3rem auto;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  // padding: 0 4rem;

  @media (max-width: 1200px) {
    // padding: 0 3rem;
    gap: 3rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    // padding: 0 2rem;
    gap: 2rem;
  }
}

.dedicatedServersLeft {
  flex: 1;
  max-width: 45%;
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  .dedicatedServersHeader {
    font-family: $font-family-header;
    font-size: 2.5rem;
    font-weight: 400;
    color: $header-color;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    .headerTextContainer {
      position: relative;
      display: inline-block;

      .headerText {
        padding-right: 55px;

        @media (max-width: 768px) {
          padding-right: 45px;
        }
      }

      .imageContainer {
        position: absolute;
        top: -20px;
        right: -10px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        overflow: hidden;
        background: white;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border: 2px solid $blue-sub-header;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;

        @media (max-width: 1098px) {
          width: 35px;
          height: 35px;
          padding: 4px;
          right: 0%;
        }

        @media (max-width: 768px) {
          width: 35px;
          height: 35px;
          padding: 4px;
          right: 0px;
        }

        .standardServer {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: all 0.3s ease;
        }
      }
    }
  }

  .dedicatedServersSubHeader {
    font-family: $font-family-header;
    font-size: 1.5rem;
    font-weight: 300;
    color: $blue-sub-header;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .dedicatedServersText {
    font-family: $font-family-header;
    font-size: 1rem;
    color: #000000;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
}

.featuresSection {
  flex: 1;
  max-width: 55%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media (max-width: 1200px) {
    gap: 1rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.featureItem {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }

  .featureIcon {
    font-size: 1.5rem;
    color: $blue-sub-header;
    flex-shrink: 0;
  }

  .featureContent {
    h4 {
      font-size: 1.1rem;
      font-weight: 600;
      color: $blue-dark;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.9rem;
      color: #666;
      line-height: 1.4;
    }
  }
}

.button {
  display: inline-block;
  padding: 1rem 2rem;
  margin-top: 2rem;
  border: 2px solid $blue-sub-header;
  color: $blue-sub-header;
  font-family: $font-family-header;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: white;

  &:hover {
    background: $blue-sub-header;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 700px) {
  .hideOnMobile {
    display: none;
  }
}
