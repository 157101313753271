@import '../../../../scss/utils/variables';

.background {
  background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
  padding: 4rem 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba($blue-header, 0.1), transparent);
  }
  
  .container {
    width: 90%;
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .formContainer {
      background: white;
      padding: 2.5rem;
      border-radius: 16px;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
      width: 100%;
      border: 1px solid rgba($blue-header, 0.1);
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(to right, $blue-header, $blue-sub-header);
      }

      @media (max-width: 768px) {
        padding: 1.5rem;
      }
    }
  
    .header {
      font-size: 2.25rem;
      font-weight: 700;
      color: $blue-dark;
      margin-bottom: 0.75rem;
      text-align: center;
      letter-spacing: -0.02em;

      @media (max-width: 768px) {
        font-size: 1.75rem;
      }
    }
  
    .text {
      font-size: 1rem;
      line-height: 1.5;
      color: #4a5568;
      text-align: center;
      max-width: 800px;
      margin: 0 auto 2rem;

      @media (max-width: 768px) {
        font-size: 0.95rem;
        margin-bottom: 1.5rem;
      }
    }
  
    .errorText {
      color: #e53e3e;
      font-size: 0.875rem;
      margin: 0.5rem 0;
      padding: 0.5rem;
      background: #fff5f5;
      border-radius: 6px;
      border: 1px solid #feb2b2;
    }
  
    .successText {
      color: #2f855a;
      font-size: 0.875rem;
      margin: 0.5rem 0;
      padding: 0.5rem;
      background: #f0fff4;
      border-radius: 6px;
      border: 1px solid #9ae6b4;
    }
  
    .quoteForm {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  
      .section {
        background: #f8fafc;
        padding: 1.5rem;
        border-radius: 12px;
        border: 1px solid rgba($blue-header, 0.1);
        transition: all 0.3s ease;

        &:hover {
          background: #f1f5f9;
          border-color: rgba($blue-header, 0.2);
        }

        h3 {
          color: $blue-dark;
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 1.25rem;
          display: flex;
          align-items: center;
          gap: 0.75rem;

          &::before {
            content: '';
            width: 3px;
            height: 20px;
            background: linear-gradient(to bottom, $blue-header, $blue-sub-header);
            border-radius: 2px;
          }
        }
  
        .formGroup {
          margin-bottom: 1rem;
          width: 100%;
          box-sizing: border-box;
  
          label {
            font-size: 0.875rem;
            font-weight: 500;
            color: $blue-dark;
            margin-bottom: 0.375rem;
            display: block;
          }
  
          input,
          select,
          textarea {
            width: 100%;
            padding: 0.625rem 0.875rem;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            font-size: 0.95rem;
            background-color: white;
            color: $blue-dark;
            transition: all 0.2s ease;
            box-sizing: border-box;
  
            &:focus {
              outline: none;
              border-color: $blue-sub-header;
              box-shadow: 0 0 0 3px rgba($blue-sub-header, 0.1);
            }
  
            &::placeholder {
              color: #a0aec0;
            }
          }
  
          select {
            appearance: none;
            background: white url('../../../../Assets/icons/Down-Black.svg') no-repeat right 1rem center;
            background-size: 12px;
            padding-right: 2.5rem;
          }
  
          textarea {
            min-height: 80px;
            resize: vertical;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
  
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        width: 100%;
        box-sizing: border-box;
  
        @media (max-width: 900px) {
          grid-template-columns: 1fr;
          gap: 0.75rem;
        }

        &:first-child {
          .formGroup {
            margin-bottom: 1rem;
          }
        }
      }
  
      .section:has(.formGroup:has(select[name="storageType"])) {
        .grid {
          margin-bottom: 1rem;
        }
      }
  
      .sendButton {
        background: linear-gradient(135deg, $blue-header, $blue-sub-header);
        color: white;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.875rem 2rem;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba($blue-sub-header, 0.2);
        margin-top: 0.5rem;
  
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 12px rgba($blue-sub-header, 0.3);
        }
  
        &:active {
          transform: translateY(0);
        }
      }
    }
  }
}

.lottieTopRight {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  pointer-events: none;
}
