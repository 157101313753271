@import "../../../../scss/utils/variables";

.background {
  position: relative;
  background: linear-gradient(to bottom, #f7f9fc 0%, #ffffff 100%);
  padding: 5rem 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(0, 132, 255, 0.1), transparent);
  }

  .container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1rem;

    .header {
      font-family: $font-family-header;
      font-size: 2.5rem;
      font-weight: 700;
      color: $blue-dark;
      margin-bottom: 2rem;
      text-align: center;
      line-height: 1.2;

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    .selectorContainer {
      position: relative;
      border-radius: 16px;
      display: flex;
      text-align: center;
      justify-content: space-around;
      align-items: stretch;
      z-index: 0;
      overflow: hidden;
      background: white;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
      border: 1px solid rgba(0, 132, 255, 0.1);
      margin-bottom: 3rem;
    }
    
    .ogden,
    .losangeles {
      width: 100%;
      padding: 1.25rem;
      text-align: center;
      cursor: pointer;
      position: relative;
      color: #666;
      font-family: $font-family-header;
      font-weight: 600;
      transition: all 0.3s ease;
    
      &.active {
        color: $blue-dark;
        background-color: rgba(0, 132, 255, 0.05);
    
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 10%;
          width: 80%;
          height: 3px;
          background: $blue-sub-header;
          border-radius: 10px;
        }
      }

      &:hover:not(.active) {
        color: $blue-sub-header;
        background-color: rgba(0, 132, 255, 0.02);
      }
    }
    
    .ogden {
      border-radius: 16px 0 0 16px;
    }

    .cardGrid {
      display: grid;
      grid-template-columns: repeat(3, minmax(300px, 1fr));
      gap: 2rem;
      margin-bottom: 3rem;

      @media (max-width: 1024px) { // Adjust for medium screens
        grid-template-columns: repeat(2, minmax(300px, 1fr)); // 2 columns
      }
    
      @media (max-width: 768px) { // Adjust for mobile screens
        grid-template-columns: 1fr; // Stack into a single column
      }
      
      .solutionsCardContainer {
        .solutionsCard {
          background: white;
          padding: 2.5rem;
          border-radius: 16px;
          position: relative;
          transition: all 0.3s ease;
          border: 1px solid rgba(0, 132, 255, 0.1);
          box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
          // height: 100%;
          display: flex;
          flex-direction: column;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 32px rgba(0, 132, 255, 0.1);
            border-color: rgba(0, 132, 255, 0.2);
          }

          .cardContent {
            flex: 1;
          }

          .solutionsCardTopRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            .solutionsCardTitle {
              font-family: $font-family-header;
              color: $blue-dark;
              font-weight: 700;
              font-size: 1.25rem;
            }

            .solutionsCardInstantDeploy {
              font-family: $font-family-header;
              font-size: 0.875rem;
              font-weight: 600;
              color: $blue-sub-header;
              padding: 0.5rem 1rem;
              background: rgba(0, 132, 255, 0.1);
              border-radius: 100px;
            }
          }

          .solutionsCardPrice {
            font-family: $font-family-header;
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 1.5rem;
            color: $blue-dark;
          }

          hr {
            border: none;
            height: 1px;
            background: rgba(0, 132, 255, 0.1);
            margin: 1.5rem 0;
          }

          .solutionsCardBottomRow {
            .solutionsCardIncludes {
              text-transform: uppercase;
              font-family: $font-family-header;
              font-size: 0.875rem;
              font-weight: 600;
              color: $blue-sub-header;
              margin-bottom: 1rem;
            }

            .solutionsList {
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                font-family: $font-family-header;
                font-size: 0.95rem;
                color: #666;
                margin-bottom: 0.75rem;

                &::before {
                  content: "";
                  width: 18px;
                  height: 18px;
                  background: url("../../../../Assets/icons/Check.svg") no-repeat center;
                  background-size: contain;
                }
              }
            }
          }

          .buttonContainer {
            margin-top: auto;
            padding-top: 2rem;
            text-align: center;
            display: flex;
          }

          .orderNow {
            display: inline-block;
            width: 100%;
            padding: 1rem;
            text-align: center;
            background: $blue-sub-header;
            color: white;
            font-family: $font-family-header;
            font-weight: 600;
            font-size: 1rem;
            border-radius: 8px;
            transition: all 0.3s ease;
            text-decoration: none;

            &:hover {
              background: darken($blue-sub-header, 5%);
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }
}

.customQuoteContainer {
  background: white;
  border-radius: 16px;
  padding: 3rem;
  border: 1px solid rgba(112, 156, 197, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

  @media(max-width: 500px) {
    padding: .5rem;
  }

  .quoteContent {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    @media(max-width: 500px) {
      padding: .5rem;
    }
  }

  .customHeader {
    font-family: $font-family-header;
    font-size: 2rem;
    font-weight: 700;
    color: $blue-dark;
    margin-bottom: 1rem;

  }

  .customColocationText {
    font-family: $font-family-header;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 2rem;
  }

  .getCustomOrderButton {
    display: inline-block;
    padding: 1rem 2rem;
    background: $blue-sub-header;
    color: white;
    font-family: $font-family-header;
    font-weight: 600;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: darken($blue-sub-header, 5%);
      transform: translateY(-2px);
    }
  }

  .formWrapper {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px solid rgba(0, 132, 255, 0.1);
  }
}

#getcoloquote {
  scroll-margin-top: 5rem;
}
