@import "../../../../scss/utils/variables";

.solutionsContainer {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 5rem 0;
  color: $blue-dark;

  @media (max-width: 600px) {
    padding: 3rem 0;
  }

  .topRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3rem;

    .header {
      font-family: $font-family-header;
      font-size: 2.5rem;
      font-weight: 700;
      color: $blue-dark;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    .subHeader {
      font-family: $font-family-header;
      font-size: 1.5rem;
      font-weight: 600;
      color: $blue-sub-header;
    }

    .dotContainer {
      display: flex;
      gap: 1rem;
      align-items: center;

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #f1f5f9;
        border: 2px solid rgba($blue-sub-header, 0.1);
        cursor: pointer;
        transition: all 0.3s ease;

        &.active {
          background: rgba($blue-sub-header, 0.1);
          border-color: $blue-sub-header;
          transform: scale(1.2);
        }

        &:hover:not(.active) {
          border-color: rgba($blue-sub-header, 0.5);
        }
      }
    }
  }

  .solutionsCardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;

    .solutionsCard {
      background: white;
      padding: 2.5rem;
      border-radius: 16px;
      position: relative;
      transition: all 0.3s ease;
      border: 1px solid rgba($blue-header, 0.1);
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(to right, $blue-header, $blue-sub-header);
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 32px rgba($blue-sub-header, 0.1);
        border-color: rgba($blue-sub-header, 0.2);
      }

      .solutionsCardTopRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        @media(max-width: 450px) {
            flex-direction: column-reverse;
        }

      
        .solutionsCardTitle {
          font-family: $font-family-header;
          color: $blue-dark;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.4;
        }

        .solutionsCardInstantDeploy {
          font-family: $font-family-header;
          font-size: 0.875rem;
          font-weight: 600;
          color: $blue-sub-header;
          padding: 0.5rem 1rem;
          background: rgba($blue-sub-header, 0.1);
          border-radius: 100px;

          @media(max-width: 450px) {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
            margin-top: -.5rem;
          }
        }
      }

      .solutionsCardPrice {
        font-family: $font-family-header;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        color: $blue-dark;
      }

      hr {
        border: none;
        height: 1px;
        background: rgba($blue-header, 0.1);
        margin: 1.5rem 0;
      }

      .orderNow {
        display: inline-block;
        max-width: 100%;
        padding: 1rem;
        text-align: center;
        background: $blue-sub-header;
        color: white;
        font-family: $font-family-header;
        font-weight: 600;
        font-size: 1rem;
        border-radius: 8px;
        transition: all 0.3s ease;
        text-decoration: none;
        margin-bottom: 1.5rem;

        &:hover {
          background: darken($blue-sub-header, 5%);
          transform: translateY(-2px);
        }
      }

      .solutionsCardBottomRow {
        .solutionsCardIncludes {
          text-transform: uppercase;
          font-family: $font-family-header;
          font-size: 0.875rem;
          font-weight: 600;
          color: $blue-sub-header;
          margin-bottom: 1rem;
        }

        .solutionsList {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            font-family: $font-family-header;
            font-size: 0.95rem;
            color: #666;
            margin-bottom: 0.75rem;

            &::before {
              content: "";
              width: 24px;
              height: 24px;
              min-width: 24px;
              border-radius: 50%;
              background: rgba($blue-sub-header, 0.1);
              background-image: url("../../../../Assets/icons/Check.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 12px;
            }
          }
        }
      }
    }
  }
}

    // Tablet styles
    @media (max-width: 1024px) {
      .solutionsContainer .topRow .header {
        font-size: $header-font-size-tablet;
        font-weight: $header-font-weight-tablet;
        margin-bottom: $header-bottom-gap-tablet;
      }
  
      .solutionsContainer .topRow .subHeader  {
        font-size: $subheader-font-size-tablet;
        font-weight: $subheader-font-weight-tablet ;
        margin-bottom: $subheader-bottom-gap-tablet;
      }
  
      .solutionsContainer .text {
        font-size: $text-font-size-tablet;
        line-height: $text-line-height-tablet;
        margin-bottom: $text-bottom-gap-tablet;
      }
    }
  
    // Mobile styles
    @media (max-width: 600px) {
      .solutionsContainer .topRow .header {
        font-size: $header-font-size-mobile ;
        font-weight: $header-font-weight-mobile;
        margin-bottom: $header-bottom-gap-mobile;
      }
  
      .solutionsContainer .topRow .subHeader {
        font-size: $subheader-font-size-mobile;
        font-weight: $subheader-font-weight-mobile;
        margin-bottom: $subheader-bottom-gap-mobile;
      }
  
      .solutionsContainer  .text {
        font-size: $text-font-size-mobile;
        line-height: $text-line-height-mobile;
        margin-bottom: $text-bottom-gap-mobile;
      }
    }